// src/pages/NotFound.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  // Redirect to the home page on component mount
  useEffect(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return null; // Optionally, you can show a loading spinner here while redirecting
};

export default NotFound;
