import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Terms from "../pages/Terms";
import Policies from "../pages/Policies";
import NotFound from "../pages/NotFound";

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/policy" element={<Policies />} />
      {/* Catch-all route for invalid paths */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ProjectRoutes;
