import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const textSize = `text-sm md:text-base lg:text-lg xl:text-lg`;
  const navigate = useNavigate()
  const navigationManager = (uri) => {
    navigate('/'+uri)
  }
  return (
    <div className="bg-gray-100 h-screen flex flex-col flex-1 justify-between">
      <div className="bg-gray-100">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-primary to-[#ef4444] text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold">
              Welcome to Players Turf
            </h1>
            <p className="mt-4 text-lg md:text-xl">
              Book your favorite sports venues easily and quickly!
            </p>
          </div>
        </section>

        {/* Features Section */}
        <section className="container mx-auto py-16 px-6 ">
          <h2 className="text-3xl font-semibold text-primary">Our Features</h2>
          <div className="my-12 grid gap-8 grid-cols-1 md:grid-cols-3">
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Easy Booking
              </h3>
              <p className="mt-4 text-gray-600">
                Book your favorite sports venue with just a few clicks!
              </p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Real-Time Availability
              </h3>
              <p className="mt-4 text-gray-600">
                Check real-time availability of venues to avoid scheduling
                conflicts.
              </p>
            </div>
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <h3 className="text-2xl font-semibold text-primary">
                Affordable Pricing
              </h3>
              <p className="mt-4 text-gray-600">
                Get the best deals on venues and enjoy your favorite sports
                affordably.
              </p>
            </div>
          </div>
        </section>
        <section className="container mx-auto py-16 px-6">
          <h2 className="text-3xl font-semibold text-primary">
            Privacy Policy and Terms
          </h2>
          <div className="my-12 flex flex-row flex-wrap gap-3">
            <div className="bg-white p-4 shadow-lg rounded-lg w-full md:w-2/6 lg:w-2/6 xl:w-2/6">
              <h3 className="text-2xl font-semibold text-primary">Terms</h3>
              <p className="mt-4 text-gray-600">
                Please read these terms and conditions carefully before using
                Our Service.
              </p>
              <button className="mt-8 px-6 py-3 bg-primary text-white font-semibold rounded hover:bg-slate-600 cursor-pointer" onClick={() => navigationManager('terms')}>
                Show Details
              </button>
            </div>
            <div className="bg-white p-4 shadow-lg rounded-lg w-full md:w-2/6 lg:w-2/6 xl:w-2/6 flex flex-col justify-between">
              <div>
                <h3 className="text-2xl font-semibold text-primary">Policy</h3>
                <p className="mt-4 text-gray-600">
                  Read the Privacy Policy for more information.
                </p>
              </div>
              <button className="mt-8 px-6 py-3 bg-primary text-white font-semibold rounded hover:bg-slate-600 w-fit cursor-pointer" onClick={() => navigationManager('policy')}>
                Show Details
              </button>
            </div>
          </div>
        </section>
      </div>

      {/* Footer Section */}
      <footer className="bg-primary text-white py-2 md:py-3 lg:py-4 xl:py-4 w-full">
        <div className="container mx-auto text-center">
          <p className={`${textSize}`}>
            © 2024 Players Turf. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
